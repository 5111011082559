import { Tab, Tabs, Cell } from "vant";
import { registerComponent } from "@/utils";
import BottomButton from "@/components/BottomButton";

export const components = registerComponent({
  Tab,
  Tabs,
  Cell,
  BottomButton,
});

export default { components };
