<template>
  <div class="particulars">
    <van-tabs
      v-if="productInfo.productCode != 'H011'"
      title-style="height:100px"
      class="total"
    >
      <van-tab v-for="(item, index) in productPlans" :key="index">
        <template #title>
          <div>
            <div style="font-weight: bold">
              {{ item.productName }}
            </div>
            <!-- <div>{{ item.productName }}</div> -->
          </div>
        </template>
        <div class="safeguard">
          <div v-for="(clause, index) in item.planDetails" :key="index + 1">
            <div class="van-action-sheet__gap"></div>
            <div class="particularsdiv">
              <van-cell
                :border="false"
                :title="clause.clauseDisplayName"
                :value="clause.clauseAmount"
              />
              {{ clause.clauseDetail.detail }}
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
    <van-tabs v-else title-style="height:100px" class="total">
      <van-tab>
        <template #title>
          <div>
            <div style="font-weight: bold">
              {{ productPlans[0].productName }}
            </div>
          </div>
        </template>
        <div class="safeguard">
          <div
            v-for="(clause, index) in productPlans[0].planDetails.slice(0, 2)"
            :key="index + 1"
          >
            <div class="van-action-sheet__gap"></div>
            <div class="particularsdiv">
              <van-cell
                :border="false"
                :title="clause.clauseDisplayName"
                :value="clause.clauseAmount"
              />
              {{ clause.clauseDetail.detail }}
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import { Vue, Component } from "vue-property-decorator";
import options from "./options";
import { State, namespace } from "vuex-class";
import { Toast } from "vant";

const ProductModule = namespace("product");
const ProductGetters = ProductModule.Getter;
const ProductState = ProductModule.State;

@Component({
  ...options,
})
class Insure extends Vue {
  @State("product") product;
  @ProductGetters("currentPlan") currentPlan;
  @ProductGetters("productPlans") productPlans;
  @ProductState("productInfo") productInfo;

  created() {
    console.log(this.productPlans);
  }
}
export default Insure;
</script>
<style lang="scss" src="./index.scss" scoped></style>
